import actionNames from '../modules/actionNames';

function baseReducersFor(resourceName, singularName) {
  const actions = actionNames(resourceName);

  return function reducers(state, action) {
    switch (action.type) {

      case actions.fetchSuccess:
        return action.payload.result;

      case actions.createSuccess:
        return [
          ...state,
          action.payload.result
        ];

      case actions.deleteSuccess:
        return state.filter((id) => {
          return action.id !== id;
        });

      case 'ENTITY_CLEAR': {
        if (action.entity === singularName) {
          return [];
        }
        break;
      }

      default:
        return state;
    }
    return state;
  };
}

export default baseReducersFor;
