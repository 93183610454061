// Misc Requirements
import baseReducersFor from './baseReducer';

// Create Base Reducers
const baseReducers = baseReducersFor('blog_posts', 'blogPost');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = [], action) => {
  switch (action.type) {

    case 'ACTION':
      return state;

    default:
      return baseReducers(state, action);
  }
};

export default reducers;
