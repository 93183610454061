import { normalize } from 'normalizr';
import { truckListSchema, truckSchema,
         locationSchema, locationListSchema,
         blogPostSchema, blogPostListSchema,
         featuredTruckSchema, featuredTruckListSchema,
         podSchema, podListSchema,
         neighborhoodSchema, neighborhoodListSchema,
         foodCategoryListSchema, foodCategorySchema,
         waitlistEntrySchema, waitlistEntryListSchema } from '../actions/schemas';

function mergeWithState(state, data, schema) {
  const newState = normalize(data, schema);
  const mergedEntities = Object.assign({}, state.entities, newState.entities);
  return Object.assign({}, state, { entities: mergedEntities });
}

function mergeListWithState(state, data, schema, name) {
  const newState = normalize(data, schema);
  const mergedEntities = Object.assign({}, state.entities, newState.entities);
  return Object.assign({}, state, { [name]: newState.result, entities: mergedEntities });
}

const apiUtils = {
  normalize(data) {
    let state = {};

    if (!data) {
      return state;
    }

    if (data.error) {
      state = Object.assign({}, state, {
        errors: {
          status: data.error,
          modal: false
        }
      });
    }

    if (data.ui) {
      const mergedUi = Object.assign({}, state.ui, data.ui);
      state = Object.assign({}, state, { ui: mergedUi });
      state.ui.loading = {};
    }

    if (data.city_config) {
      state = Object.assign({}, state, { cityConfig: data.city_config });
    }

    if (data.pagination && data.blog_posts && !data.trucks) {
      state = Object.assign({}, state, {
        pagination: {
          blog_post: data.pagination
        }
      });
    }

    if (data.pagination && data.trucks) {
      state = Object.assign({}, state, {
        pagination: {
          truck: data.pagination
        }
      });
    }

    const entities = [
      { data: data.neighborhood, schema: neighborhoodSchema },
      { data: data.location, schema: locationSchema },
      { data: data.truck, schema: truckSchema },
      { data: data.blog_post, schema: blogPostSchema },
    ];

    entities.forEach((entity) => {
      if (entity.data) {
        state = mergeWithState(state, entity.data, entity.schema, entity.name);
      }
    });

    const listEntities = [
      { data: data.food_categories, schema: foodCategoryListSchema, name: 'foodCategories' },
      { data: data.waitlist_entries, schema: waitlistEntryListSchema, name: 'waitlistEntries' },
      { data: data.featured_trucks, schema: featuredTruckListSchema, name: 'featuredTrucks' },
      { data: data.neighborhoods, schema: neighborhoodListSchema, name: 'neighborhoods' },
      { data: data.locations, schema: locationListSchema, name: 'locations' },
      { data: data.pods, schema: podListSchema, name: 'pods' },
      { data: data.trucks, schema: truckListSchema, name: 'trucks' },
      { data: data.blog_posts, schema: blogPostListSchema, name: 'blogPosts' },
    ];

    listEntities.forEach((listEntity) => {
      if (listEntity.data) {
        state = mergeListWithState(state, listEntity.data, listEntity.schema, listEntity.name);
      }
    });

    return state;
  }
};

export default apiUtils;
