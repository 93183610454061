const entitiesReducer = (state = {}, action) => {
  const payload = action.payload;

  if (payload && payload.entities) {
    const newState = Object.assign({}, state);

    // Update just the child items
    if (action.single) {
      for (const [entityKey, entityValue] of Object.entries(payload.entities)) {
        for (const [itemKey, itemValue] of Object.entries(entityValue)) {
          if (!newState[entityKey]) {
            newState[entityKey] = {};
          }
          newState[entityKey][itemKey] = itemValue;
        }
      }

      return newState;
    }

    // Update all the parent entities
    for (const [key, value] of Object.entries(payload.entities)) {
      newState[key] = Object.assign({}, newState[key], value);
    }

    return newState;
  }

  return state;
};

export default entitiesReducer;
