import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router';
import authMiddleware from '../modules/authMiddleware';
import apiUtils from '../modules/apiUtils';
import * as Sentry from "@sentry/react";
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import notices from '../reducers/noticeReducer';
import trucks from '../reducers/trucksReducer';
import featuredTrucks from '../reducers/featuredTrucksReducer';
import trucksForSelect from '../reducers/trucksForSelectReducer';
import locations from '../reducers/locationsReducer';
import events from '../reducers/eventsReducer';
import pods from '../reducers/podsReducer';
import notifications from '../reducers/notificationsReducer';
import comments from '../reducers/commentsReducer';
import plans from '../reducers/plansReducer';
import users from '../reducers/usersReducer';
import subscriptions from '../reducers/subscriptionsReducer';
import neighborhoods from '../reducers/neighborhoodsReducer';
import foodCategories from '../reducers/foodCategoriesReducer';
import waitlistEntries from '../reducers/waitlistEntriesReducer';
import messages from '../reducers/messagesReducer';
import revenueMonths from '../reducers/revenueMonthsReducer';
import cateringRequests from '../reducers/cateringRequestsReducer';
import blogPosts from '../reducers/blogPostsReducer';
import auth from '../reducers/authReducer';
import entities from '../reducers/entitiesReducer';
import pagination from '../reducers/paginationReducer';
import ui from '../reducers/uiReducer';
import errors from '../reducers/errorsReducer';
import instagramPosts from '../reducers/instagramReducer';
import cityConfig from '../reducers/cityConfigReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Options
});

function getStore(props, history, railsContext) {
  const initialState = apiUtils.normalize(props.data);

  const store = configureStore({
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // serializableCheck: false,
        // TODO our whole redux setup is not immutable, so disabling this check for now.
        immutableCheck: false,
      }).concat(
        authMiddleware,
        routerMiddleware(history)
      ),
    reducer: {
      router: connectRouter(history),
      entities,
      trucks,
      featuredTrucks,
      trucksForSelect,
      locations,
      events,
      pods,
      notifications,
      comments,
      plans,
      users,
      subscriptions,
      neighborhoods,
      foodCategories,
      waitlistEntries,
      cateringRequests,
      blogPosts,
      messages,
      revenueMonths,
      notices,
      auth,
      ui,
      errors,
      pagination,
      form: formReducer,
      instagramPosts,
      cityConfig
    },
    enhancers: (getDefaultEnhancers) => [...getDefaultEnhancers(), sentryReduxEnhancer],
  })
  return store;
}

export default getStore;
