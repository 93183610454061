import {
  USER_ACCOUNT_UPDATED,
  USER_TOKEN_VALIDATED,
  USER_TOKEN_VALIDATING,
  USER_REGISTERED,
  USER_REGISTERING,
  USER_REGISTER_FAILED,
  USER_LOGGED_OUT,
  USER_LOGGED_IN,
  USER_LOGIN_FAILED,
  USER_LOGGING_IN,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_RESET_REQUESTING,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_REQUEST_FAILED
} from '../actions/authActions';

const initialState = {
  initialized: false,
  fetchingAuthStatus: false,
  validatingToken: false
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    // User type change

    case 'USERS_UPDATE_SUCCESS': {
      const { entities, result } = action.payload;
      const user = entities.user[result];
      const newState = Object.assign({}, state);
      if (state.userData.id === user.id) {
        newState.userData.catering_user = user.catering_user;
      }
      return newState;
    }

    // Account Update

    case USER_ACCOUNT_UPDATED:
      return Object.assign({}, state, {
        userData: action.response
      });

    // Token Validation

    case USER_TOKEN_VALIDATING:
      return Object.assign({}, state, {
        validatingToken: true
      });

    case USER_TOKEN_VALIDATED:
      return Object.assign({}, state, {
        validatingToken: false,
        initialized: true,
        userData: action.response
      });

    // Registration

    case USER_REGISTERING:
      return Object.assign({}, state, {
        userRegistering: true,
        userRegisterFailed: false
      });

    case USER_REGISTERED:
      return Object.assign({}, state, {
        userRegistering: false,
        userRegistered: true
      });

    case USER_REGISTER_FAILED:
      return Object.assign({}, state, {
        userRegistering: false,
        userRegisterFailed: true,
        message: action.message
      });

    // Log in / Log out

    case USER_LOGGED_IN: {
      const admin = action.userData.admin;

      return Object.assign({}, state, {
        fetchingAuthStatus: false,
        loggingIn: false,
        loggedIn: true,
        initialized: true,
        message: null,
        userData: action.userData,
        admin
      });
    }

    case USER_LOGGING_IN:
      return Object.assign({}, state, {
        loginFailed: false,
        loggingIn: true,
        message: null
      });

    case USER_LOGIN_FAILED:
      return Object.assign({}, state, {
        fetchingAuthStatus: false,
        loggingIn: false,
        loggedIn: false,
        initialized: true,
        loginFailed: true,
        message: action.message
      });

    case USER_LOGGED_OUT:
      return Object.assign({}, state, {
        loggedIn: false,
        initialized: true,
        fetchingAuthStatus: false,
        userData: null
      });

    // Password reset

    case PASSWORD_RESET_REQUESTING:
      return Object.assign({}, state, {
        reqestingPasswordReset: true,
        passwordResetRequested: false,
        passwordResetRequestFailed: false
      });

    case PASSWORD_RESET_REQUESTED:
      return Object.assign({}, state, {
        reqestingPasswordReset: false,
        passwordResetRequested: true,
        passwordResetRequestFailed: false
      });

    case PASSWORD_RESET_REQUEST_FAILED:
      return Object.assign({}, state, {
        reqestingPasswordReset: false,
        passwordResetRequested: false,
        passwordResetRequestFailed: true,
        message: action.message
      });

    case PASSWORD_CHANGE_FAILED:
      return Object.assign({}, state, {
        reqestingPasswordReset: false,
        passwordResetRequested: false,
        passwordResetRequestFailed: true,
        message: action.message
      });

    default:
      return state;
  }
};

export default auth;
