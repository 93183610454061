// See React on Rails Pro's spec/loadable/README.md  for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';

import ClientApp from '../../../javascripts/App';

const App = (props, _railsContext, domNodeId) => {
  loadableReady(() => {
    const root = document.getElementById(domNodeId);
    const app = React.createElement(ClientApp, { ...props, railsContext: _railsContext });
    hydrateRoot(root, app);
  });
};

export default App;
