// Misc Requirements
import baseReducersFor from './baseReducer';

// Create Base Reducers
const baseReducers = baseReducersFor('events', 'event');

// Custom Reducers and Base Reducer Overrides
const reducers = (state = [], action) => {
  switch (action.type) {

    case 'EVENT_DELETE_SUCCESS':
      return state.filter((id) => {
        return !action.deletedOccurrences.includes(id)
      });

    default:
      return baseReducers(state, action);
  }
};

export default reducers;
