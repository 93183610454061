import {
  CLEAR_SERVER_RENDERED_PATH,
} from '../actions/uiActions';

const initialState = {
  serverRenderedPath: null,
  loading: {}
};

const ENTITIES = ['USER', 'TRUCK', 'POD', 'LOCATION', 'NEIGHBORHOOD', 'NOTIFICATION',
  'COMMENT', 'CATERING', 'EVENT', 'FOOD', 'BLOG', 'PLAN', 'SUBSCRIPTION'];

const IGNORED_ENTITIES = ['FOR_SELECT'];

const START_FETCH_ACTIONS = ['FETCH_START', 'FETCHONE_START'];
const END_FETCH_ACTIONS = ['FETCH_SUCCESS', 'FETCHONE_SUCCESS'];

const START_UPDATE_ACTIONS = [
  'UPDATE_START', 'CREATE_START',
  'DELETE_START', 'REGISTERING'
];
const END_UPDATE_ACTIONS = [
  'UPDATE_SUCCESS', 'CREATE_SUCCESS',
  'DELETE_SUCCESS', 'REGISTERED', 'FAILED'
];

const loadingReducer = (state, action) => {
  const type = action.type;
  let startFetchAction = null;
  let startUpdateAction = null;
  let endFetchAction = null;
  let endUpdateAction = null;
  let loading = false;

  if (type === 'DISPLAY_ERROR') {
    let newState = Object.assign({}, state);
    for (const [key, value] of Object.entries(state.loading)) {
      newState.loading[key] = false;
    }
    return newState;
  }

  const ignoredEntity = IGNORED_ENTITIES.find((e) => {
    return type.includes(e);
  });

  if (ignoredEntity) return state;

  const entity = ENTITIES.find((e) => {
    return type.includes(e);
  });

  startFetchAction = START_FETCH_ACTIONS.find((e) => {
    return type.includes(e);
  });

  endFetchAction = END_FETCH_ACTIONS.find((e) => {
    return type.includes(e);
  });

  startUpdateAction = START_UPDATE_ACTIONS.find((e) => {
    return type.includes(e);
  });

  endUpdateAction = END_UPDATE_ACTIONS.find((e) => {
    return type.includes(e);
  });

  if (entity && (startFetchAction || endFetchAction)) {
    if (startFetchAction) {
      loading = true;
    }
    const fetchOne = type.includes('ONE');
    const newState = Object.assign({}, state);
    const entityLowerCase = entity.toLowerCase();

    newState.loading[`${entityLowerCase}Fetch`] = loading;

    if (fetchOne) {
      newState.loading[`${entityLowerCase}FetchOne`] = loading;
    } else {
      newState.loading[`${entityLowerCase}FetchIndex`] = loading;
    }

    return newState;
  }

  if (entity && (startUpdateAction || endUpdateAction)) {
    if (startUpdateAction) {
      loading = true;
    }
    const newState = Object.assign({}, state);
    const entityLowerCase = entity.toLowerCase();
    newState.loading[`${entityLowerCase}Update`] = loading;
    return newState;
  }
  return state;
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {

    case CLEAR_SERVER_RENDERED_PATH:
      return Object.assign({}, state, {
        serverRenderedPath: null
      });

    default:
      return loadingReducer(state, action);
  }
};

export default uiReducer;
